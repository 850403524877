<template>
  <div>
    <PatientButton @getPatientInfo="getPatientInfo" />
    <div class="card">
      <van-form ref="vanForm" v-model="formData" @submit="onSubmit">
        <van-field
          v-model="formData.mobile"
          name="mobile"
          label="联系电话"
          placeholder="请填写联系电话"
          required
          :rules="[
            { required: true, message: '' },
            {
              validator: (value) => {
                return /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/.test(
                  value
                )
              },
              message: '请输入正确格式的手机号码',
              trigger: 'onBlur',
            },
          ]"
        />
        <van-field
          readonly
          clickable
          :value="formData.visitDate"
          name="visitDate"
          label="预期入院时间"
          placeholder="请选择入院时间"
          is-link
          required
          :rules="[{ required: true, message: '' }]"
          @click="showRyPicker = true"
        />
        <van-field
          readonly
          clickable
          name="copyUse"
          label="期望入住病房"
          :value="formData.bedTypeText"
          placeholder="请选择病房类型"
          is-link
          required
          :rules="[{ required: true, message: '' }]"
          @click="showBedTypePicker = true"
        />
        <van-field
          readonly
          clickable
          name="edc"
          :value="formData.edc"
          label="预产期"
          placeholder="请选择预产期"
          is-link
          required
          :rules="[{ required: true, message: '' }]"
          @click="showYCPicker = true"
        />
        <van-calendar
          v-model="showRyPicker"
          title="预期入院时间"
          :min-date="visitMinDate"
          :max-date="maxDate"
          color="#1989FA"
          @confirm="(time) => onConfirm(time, 'visitDate', 'showRyPicker')"
          @cancel="showRyPicker = false"
        />
        <van-calendar
          v-model="showYCPicker"
          title="预产期"
          color="#1989FA"
          :min-date="minDate"
          @confirm="(time) => onConfirm(time, 'edc', 'showYCPicker')"
          @cancel="showYCPicker = false"
        />
        <van-popup v-model="showBedTypePicker" round position="bottom">
          <van-picker
            title="病房类型"
            show-toolbar
            :columns="bedTypeOptions"
            value-key="label"
            :colunms-field-names="{ text: 'label' }"
            @cancel="showBedTypePicker = false"
            @confirm="bedTypeConfirm"
          />
        </van-popup>
      </van-form>
    </div>
    <van-button type="info" round @click="onSubmit">
      <div>提交申请</div>
    </van-button>
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import base64 from '@/util/Base64'
  import PatientButton from '@/components/PatientButton'
  import { Dialog, Notify, Toast } from 'vant'
  import { getOption } from '@/api/com/dic/dic'
  import { confirmBed } from '@/api/his/his'

  export default {
    name: 'Confirm',
    components: { PatientButton },
    data() {
      return {
        userInfo: {},
        minDate: new Date(
          moment(Date.now())
            .add(-1, 'year')
            .format('YYYY-MM-DD')
        ),
        visitMinDate: new Date(
          moment(Date.now())
            .add(1, 'day')
            .format('YYYY-MM-DD')
        ),
        date: new Date(moment(new Date()).format('YYYY-MM-DD')),
        maxDate: new Date(),
        showRyPicker: false,
        showYCPicker: false,
        showBedTypePicker: false,
        formData: {
          orgIndex: '',
          idCard: '',
          patName: '',
          mobile: '',
          visitDate: '',
          bedType: null,
          bedTypeText: '',
          edc: '',
        },
        bedTypeOptions: [],
      }
    },
    async mounted() {
      if (base64.decode(this.$route.query.bedTypeText) !== 'undefined') {
        this.formData.bedType = Number(base64.decode(this.$route.query.bedType))
        this.formData.bedTypeText = base64.decode(this.$route.query.bedTypeText)
      }
      let data = JSON.parse(sessionStorage.getItem('day'))
      let bedDay = data.bedDay
      this.maxDate = new Date(
        moment(Date.now())
          .add(bedDay, 'day')
          .format('YYYY-MM-DD')
      )
      await this.getOption()
      this.getPatientInfo()
    },

    methods: {
      //获取就诊人信息
      getPatientInfo() {
        this.userInfo = JSON.parse(store.getters['user/userInfo'])
        if (this.userInfo != null) {
          this.formData.idCard = this.userInfo.card_No
          this.formData.patName = this.userInfo.name
          this.formData.mobile = this.userInfo.mobile
        } else this.getTip('暂未绑定就诊人，点击确定前往。')
      },

      //提示，跳转就诊人管理
      getTip(msg) {
        Dialog.alert({
          message: msg,
          closeOnPopstate: false,
        }).then(() => {
          this.$router.push({
            path: '/message',
            query: { sign: 'gzrgl' },
          })
        })
      },

      //日期选择
      onConfirm(date, type, picker) {
        this.formData[type] = moment(date).format('YYYY-MM-DD')
        this[picker] = false
      },

      //床位类型选择
      bedTypeConfirm(item) {
        this.formData.bedType = item.value
        this.formData.bedTypeText = item.label
        this.showBedTypePicker = false
      },

      //提交
      onSubmit() {
        if (this.userInfo.card_No === '') {
          this.getTip('当前就诊人未通过身份证绑定，请更换就诊人')
          return
        }
        this.$refs['vanForm'].validate().then(() => {
          Dialog.confirm({
            title: '提交确认',
            message: '确定要预约当前床位吗',
          })
            .then(async () => {
              Toast.loading({
                duration: 0, // 持续展示 toast
                message: '正在提交中...',
                forbidClick: true,
              })
              const { msg } = await confirmBed(this.formData)
              Toast.clear()
              Dialog.alert({
                message: msg,
              }).then(() =>
                location.replace(
                  'https://i.xikang.com/userserver/normalHospital/home?xkOrgCode=XK33028101DEDBAGJI&weichatNo=YYSFYBJY&hospitalKey=yysfybjy#'
                )
              )
            })
            .catch(() => {
              // on cancel
            })
        })
      },

      //获取床位类型选项
      async getOption() {
        const { data } = await getOption({ typeID: 6 })
        for (let i = 0; i < data.options.length; i++)
          data.options[i].value = Number(data.options[i].value)
        this.bedTypeOptions = data.options
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-button--info {
    width: 95%;
    margin-left: 2.5%;
  }
  .card {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    font-family: 'Microsoft Yahei';
    .des {
      text-align: center;
    }
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
        color: #28a881;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px;
    }
  }
</style>
