import { request } from '@/util/request'

export function getList(params) {
  return request({
    url: '/api/ComDic/GetList',
    method: 'get',
    params,
  })
}

export function pageInitDic(params) {
  return request({
    url: '/api/ComDic/PageInitDic',
    method: 'get',
    params,
  })
}

export function doAdd(data) {
  return request({
    url: '/api/ComDic/Add',
    method: 'post',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/api/ComDic/Update',
    method: 'post',
    data,
  })
}

export function doDelete(params) {
  return request({
    url: '/api/ComDic/DeleteById',
    method: 'get',
    params,
  })
}

export function getOption(params) {
  return request({
    url: '/api/ComDic/GetOption',
    method: 'get',
    params,
  })
}
